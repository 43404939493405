.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* CSS */
.banner-img {
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s, filter 0.2s, margin-top 0.2s; /* Add margin-top to the transition */
}

.banner-img img {
  max-width: 100%;
  height: auto;
  display: block;
  transition: filter 0.2s;
}

.banner-img:hover {
  margin-top: -50px; /* Move the image slightly up on hover */
  transform: translateY(-5px);
}

.banner-img:hover img {
  filter: brightness(1.4); /* Add a glow effect on hover */
  transform: perspective(1000px) rotateX(15deg);
  transition: transform 0.3s ease;
}

/* CSS for ScrollToTopButton */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1f2278;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 9999999;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.scroll-to-top-button:hover {
  background-color: rgb(0, 81, 168);
}
#typing-text {
  overflow: hidden;
  border-right: 2px solid #333; /* Cursor effect */
  white-space: nowrap; /* Prevent text from wrapping */
  margin: 0;
}

#typing-text::after {
  content: '|'; /* Blinking cursor */
  animation: cursor-blink 0.7s infinite;
}

@keyframes cursor-blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
